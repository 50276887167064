import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import Keyboard from '../../components/Keyboard/Keyboard';
import VendingDispensed from './VendingDispensed';
import Vendingoutservice from './Vendingoutservice';
import VendingModalConfirmation from './VendingModalConfirmation';
import { Button } from 'react-bootstrap'
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod'
import { DiscountFetchAPI } from '../../api/Discounts';
import { MachinesFetchAPI } from '../../api/Machines';
import { PositionFetchAPI } from '../../api/Position';
import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import { DivicesFechtAPI } from '../../api/Divices';
import { VendingFetchAPI } from '../../api/LocalRequest/Vending';
import { RenderIf } from '../../components/utils/RenderIf';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { OrdersFetchAPI } from '../../api/Orders';
import { OrderReturnFetchAPI } from '../../api/Paymentpost/OrderReturn';
import { YappyFetchAPI } from '../../api/Paymentpost/Yappy';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { VendingSnakkyFetchAPI } from '../../api/LocalRequest/VendingSnakky';
import VendingModalYappy from './VendingModalYappy';
import ModalYappyQr from './ModalYappyQr';
import oledComunication from './OledComunication';
import Swal from 'sweetalert2';

import checkImg from './img/check.gif'
import errorImg from './img/error.gif';
import loadingImg from './img/loading.gif'

function VendingMetPayments({ showModalPayment, setShowModalPayment, shoppingCart, invoiceTotal, invoiceTotalItbms, invoiceSubtotal, invoiceTotalDiscount, dataClient, setDataClient, setShoppingCart, setShowModalShopingCart, setEventHandlerShoppingCart, setEventHandlerMenu, setStatusScreen, statusComunicationVending, calculateInvoiceTotals, groups, taxesArrayAll, orderNumber, setOrderNumber, payments, setPayments, oledScreenPayments }) {
  const [displayKeyboard, setDisplayKeyboard] = useState('')
  const [item, setItem] = useState({ name: "", price: "", img: "" })
  const [data, setData] = useState({ name: "No registrado", ruc: "0-000-00", dv: "00" });
  const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago" })
  // !Cmabiar el de dispense 
  const [showModalDispense, setShowModalDispense] = useState(false);
  const [showModalPaymentProcess, setShowModalPaymentProcess] = useState(false);
  const [dataParamsPaymentVoucher, setDataParamsPaymentVoucher] = useState({ authorizationNumber: "", referenceNumber: "", systemTraceNumber: "" })
  const [dataInfoPaymentSelect, setDataInfoPaymentSelect] = useState({})
  const [showModalDatos, setShowModalDatos] = useState(false);
  const [showModaloutService, setShowModaloutService] = useState(false)
  const [paymentSelect, setPaymentSelect] = useState("")
  const [showModalYappy, setShowModalYappy] = useState(false);
  const [dataNoteCredit, setDataNoteCredit] = useState(false);
  const [dataRutaQr, setDataRutaQr] = useState("");
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [itemReadyDispense, setItemReadyDispense] = useState([]);
  const [voucher_Order, setVoucher_Order] = useState({});
  const [order_code_register, setOrder_code_register] = useState("")
  const [transaction_code_register, setTransaction_code_register] = useState("")
  const [eventDispense, setEventDispense] = useState(0);
  const [title, setTitle] = useState("Ingrese el ruc");
  const [statusError, setStatusError] = useState(false);
  const [ready, setReady] = useState(false);
  const [selectPaymentYappy, setSelectPaymentYappy] = useState({})
  const [modalYappyQr, setModalYappyQr] = useState(false);
  const [dataYappy, setDataYappy] = useState({});
  const [dataHash, setDataHash] = useState("");
  const [statusRequestYappy, setStatusRequestYappy] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({ payment: "", payment_id: 0, payment_data: {} })
  let token = localStorage.getItem('x-access-token');
  let machine = JSON.parse(localStorage.getItem('machine_vending'))
  let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
  let business = JSON.parse(localStorage.getItem('business_vending'));


  //input // discount
  const inputRefLog = useRef(null);

  setTimeout(() => {
    if (inputRefLog.current != null) {
      inputRefLog.current.focus()
    }

  }, 1000);

  const handleChange = (txt) => {
    /*if (e.key === 'Enter') {
      let inputValue = e.target.value
      let arrayDiscount = inputValue.split("X");
      getDiscountByCode(arrayDiscount[1]);

      document.getElementById("inputCodeQrDiscount").value = ""

    }*/

    console.log(txt);
    console.log(payments);

    if (txt == '0') {
      setShowModalPayment(false);
      clearShoppingCart()
    } else {


      if (parseInt(txt) <= payments.length) {
        let inputNum = (txt - 1)

        if (machine.machine_subtype_code === "VGG") {
          oledComunication(0, '2,0')
          setTimeout(() => {
            oledComunication(0, '2,1,1,30,20,Cobrando...')
          }, 50);

          setTimeout(() => {
            oledComunication(0, '2,1,1,30,30,' + txt + '.' + payments[inputNum].payment)
          }, 100);

          setTimeout(() => {
            oledComunication(0, '2,1,0.5,0,55,Tx:$' + invoiceTotalItbms + ' Total:$' + invoiceTotal)
          }, 150);
        }

        getOrderNumber()
        selectprocessPayment(payments[inputNum])
      } else {

      }
    }
    clearInputPayment()
  }

  const clearInputPayment = (txt) => {
    if (txt == undefined) { inputRefLog.current.value = "" } else { inputRefLog.current.value = txt }
    inputRefLog.current.focus()
    console.log('Limpiar');
  }


  //get dicount by code and apply discount
  const getDiscountByCode = async (code) => {
    //console.log("Este es el codigo de descuento")
    //console.log(code)
    try {
      const response = await DiscountFetchAPI.getDiscountByCode(code, token);
      let discountRate = response.data.data.discount.discountRate
      console.log(discountRate)
      console.log(shoppingCart)

      for (let i = 0; i < shoppingCart.length; i++) {

        let discount = ((shoppingCart[i].price * discountRate) / 100)
        console.log(discount)

        shoppingCart[i].unit_discount = discount;
        shoppingCart[i].product_discount = discount;
        shoppingCart[i].descuento = discount;
        shoppingCart[i].discount = discount;

      }
      setShoppingCart(shoppingCart);
      calculateInvoiceTotals(shoppingCart)
      ErrorAlert("", "Descuento aplicado con exito", "success")

    } catch (err) {
      ErrorAlert("", "Descuento no encontrado", "error")
      console.log(err)

    }
  }





  //Function to get the payments method
  const getPaymentsMethod = async () => {
    try {
      const response = await PaymentMethodFetchAPI.getPaymentMethod(machine.id, token);
      setPayments(response.data.data)



    } catch (err) {
      console.log(err);
    }
  }




  //get data client authentication
  const authDgi = async () => {
    let message = "";
    let titleAlert = "";
    let icon = "";
    try {
      const response = await AuthDgiFetchAPI.authenticationRuc(token, data)

      let dataDgi = response.data
      if (dataDgi.dgi_response.gResProc) {
        if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
          data.dv = response.data.dgi_response.dDV;
          data.ruc = response.data.dgi_response.dRuc;
          data.name = response.data.dgi_response.dNomb;
          setData(data)
          setShowModalDatos(false)//Hiden modal data

          message = "Datos del clientes obtenidos por la DGI"
          titleAlert = "Datos obtenido correctamente"
          icon = "success"
          ErrorAlert(message, title, icon)

        } else {
          message = "Ingrese los datos manualmente."
          titleAlert = "Datos no encontrados"
          icon = "warning"
          ErrorAlert(message, title, icon)
        }
      }
    } catch (err) {
      ErrorAlert("", "Datos no encontrados en la DGI", "error")
      console.log(err);
    }
  }

  //function save data client 8-712-140
  const saveDataClient = async () => {
    if (data.ruc == "0-000-00") {
      console.log("hola");
      data.ruc = displayKeyboard;

      setDisplayKeyboard("")
      setTitle("Ingrese el nombre:")
      LoadingAlert()
      authDgi()
    } else if (data.name == "Cliente") {
      data.name = displayKeyboard;
      setDisplayKeyboard("")
      setTitle("Ingrese el dv:")
    } else if (data.dv == "00") {
      data.dv = displayKeyboard;
      setData(data)
      setShowModalDatos(false)//Hiden modal data
      setDisplayKeyboard("")
      setTitle("Ingrese el ruc:")
    }
  }

  //quiestion to change data client
  const handleDataClient = () => {
    if (data.ruc == "0-000-00") {
      setShowModalDatos(true)
    } else {
      Swal.fire({
        title: 'Quieres cambiar los datos?',
        text: "Al aceptar se borraran lo datos que ya tenias.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          setData({ name: "Cliente", ruc: "0-000-00", dv: "00" })
          setShowModalDatos(true)
        }
      })
    }
  }

  //notication info to the server local c#
  const infoMachineLocal = async () => {
    const JsonTr = [{
      Tipo: 'INFORMACION',
      Producto: machine.id,
      Monto: 0,
      TipoVenta: 'INFORMACION',
      printer_name: "",
      x_access_token: token,
      branchoffice_id: branchoffice.id
    }];
    try {
      const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr)
    } catch (err) {
      console.log(err);
    }
  }

  //check payment
  const paymentValidYappy = async (e, payment, phone) => {

    let EMVStreamResponseReponse = "0"
    setShowModalPaymentProcess(true)
    e.preventDefault();
    setDataInfoPaymentSelect(payment.payment)
    let type_Payment = payment.payment

    try {
      const lastOrder = await OrdersFetchAPI.getNumberOrder(branchoffice.id, token);
      let JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: invoiceTotalItbms.toFixed(2),
        Descuento: invoiceTotalDiscount,
        Monto: invoiceTotal.toFixed(2),
        TipoVenta: type_Payment.toUpperCase(),
        paymentMethod: payment,
        orderid: (parseInt(lastOrder.data.last_transaction_code) + 1),
        taxes: invoiceTotalItbms.toFixed(2),
        subtotal: (invoiceTotal.toFixed(2) - invoiceTotalItbms.toFixed(2)),
        total: invoiceTotal,
        tel: "507" + phone,
      }];


      const response = await RequestLocalFetchAPI.postPaymentMethodVending(JsonTr);
      let responseCode = response.data.EMVStreamResponse.responseCode;
      let description = response.data.EMVStreamResponse.responseCodeDescription
      setVoucher_Order("0")

      if (responseCode == "20") {
        setStatusScreen(true);

        setPaymentApproval({ status: 1, description: description });

      } else {
        setPaymentApproval({ status: 2, description: description });
      }
      setTimeout(() => {
        if (responseCode == "20") {
          setShowModalDispense(true);
          setShowModalPayment(false)
          registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);

        }
        setShowModalPaymentProcess(false)
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 3000);
    } catch (err) {
      console.log(err);
    }

  }


  //check payment
  const paymentValid = async (payment) => {
    setPaymentSelect(payment.payment)
    let EMVStreamResponseReponse = "0"
    setShowModalPaymentProcess(true)

    setDataInfoPaymentSelect(payment.payment)
    if (payment.payment == "Credito") {
      setPaymentApproval({ status: 1, description: "APROBADO" });
      oledsuccess()
      setVoucher_Order("0")
      setStatusScreen(true);
      //formatVoucherString("Terminald ID:                   WSP00065          *  ANULACION  *           SMART PAY - WSP         418794XXXXXX7313AUTH: 238912                    TRX: 608REF:                            13714333FECHA: 21/07/2023               11:02 AMTOTAL:                         USD. 0.01       FIRMA: _______                                                          ** FIN  **           - - - - - - - - - - - - - - - - - - - -        *  COPIA CLIENTE  *         Terminald ID:                   WSP00065          *  ANULACION  *           SMART PAY - WSP         418794XXXXXX7313AUTH: 238912                    TRX: 608REF:                            13714333FECHA: 21/07/2023               11:02 AMTOTAL:                         USD. 0.01           ** FIN  **           ")


      setTimeout(() => {
        setShowModalDispense(true);
        registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);
        setShowModalPaymentProcess(false)
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 3000);
    } else {

      let type_Payment = ""
      if (payment.payment == "MasterCard") {
        type_Payment = "MC"
      } else {
        type_Payment = payment.payment
      }
      try {
        const lastOrder = await OrdersFetchAPI.getNumberOrder(branchoffice.id, token);
        let JsonTr = [{
          Tipo: 'SALE',
          Producto: 'COBRAR',
          Itbms: invoiceTotalItbms,
          Descuento: invoiceTotalDiscount,
          Monto: invoiceTotal.toFixed(2),
          TipoVenta: type_Payment.toUpperCase(),
          paymentMethod: payment,
          orderid: (parseInt(lastOrder.data.last_transaction_code) + 1),
        }];



        const response = await RequestLocalFetchAPI.postPaymentMethodVending(JsonTr);
        let responseCode = response.data.EMVStreamResponse.responseCode;
        let description = response.data.EMVStreamResponse.responseCodeDescription
        console.log(response.data.EMVStreamResponse.voucher);
        setVoucher_Order(response.data.EMVStreamResponse.voucher);



        if (type_Payment != "Yappy" && type_Payment != "Efectivo" && type_Payment != "Credito") {
          setDataParamsPaymentVoucher({ authorizationNumber: response.data.EMVStreamResponse.authorizationNumber, referenceNumber: response.data.EMVStreamResponse.referenceNumber, systemTraceNumber: response.data.EMVStreamResponse.systemTraceNumber })
          EMVStreamResponseReponse = response.data.EMVStreamResponse
        } else {
          setVoucher_Order("0")
        }


        if (responseCode == "00") {
          setStatusScreen(true);
          setPaymentApproval({ status: 1, description: description });
          oledsuccess()
        } else {
          setPaymentApproval({ status: 2, description: description });
          olederror(description);
        }
        setTimeout(() => {
          if (responseCode == "00") {
            setShowModalDispense(true);
            registerOrder(payment.payment_id, EMVStreamResponseReponse, payment);
          }
          setShowModalPaymentProcess(false)
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      } catch (err) {
        setShowModalPaymentProcess(false)
        olederror()
        console.log(err);
      }
    }
  }

  // funtion convert format voucher
  const formatVoucherString = (voucher) => {
    // Extraer el número de tarjeta ocultando los dígitos intermedios
    const maskedVoucher = voucher.replace(/(\d{6})\d+(\d{4})/g, "$1XXXXXX$2");

    // Construir el string formateado del voucher
    const formattedVoucher = `${maskedVoucher}`;
    console.log(formattedVoucher);
    setVoucher_Order(formattedVoucher)
    //return formattedVoucher;
  };


  //Return amount order
  const voidCreditDebit = async () => {
    if (dataInfoPaymentSelect == "Credito") {
      registerOrderTempReturn({ EMVStreamResponse: "0" })
    } else {


      try {
        LoadingAlert("Procesando devolucion...")
        //request return total of the transaction
        const json = [{ Tipo: "VOID", authorizationNumber: dataParamsPaymentVoucher.authorizationNumber, referenceNumber: dataParamsPaymentVoucher.referenceNumber, systemTraceNumber: dataParamsPaymentVoucher.systemTraceNumber }];
        const response = await RequestLocalFetchAPI.postPaymentMethodVendingVoid(json);
        console.log(response.data);
        let newVoucher = { EMVStreamResponse: response.data.EMVStreamResponse }
        setVoucher_Order(response.data.EMVStreamResponse.voucher);


        if (response.data.EMVStreamResponse.responseCodeDescription) {
          if (response.data.EMVStreamResponse.responseCodeDescription == "APROBADA") {
            registerOrderTempReturn(newVoucher)
          } else {
            ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
          }
        } else {
          ErrorAlert("No se aplico la devolucion.", "Error en la devolucion", "error")
        }

      } catch (err) {
        console.log(err);
      }
    }
  }

  //register order return
  const registerOrderTempReturn = async (data_bank_voucher_response) => {

    let token = localStorage.getItem('x-access-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
    let business = JSON.parse(localStorage.getItem('business_vending'));

    try {
      const response = await OrderReturnFetchAPI.registerOrderReturn(transaction_code_register, business.code, branchoffice.code, token, data_bank_voucher_response);
      console.log(response);
      //getOrder(response.data.data.transaction_code)
      ErrorAlert("Devolucion exitosa.", "La devolucion se realizo correctamente ", "success")
      console.log(response.data.data.dgi_qr);

      if (response.data.data.dgi_qr == "") {
        setDataRutaQr("0")
      } else {
        setDataRutaQr(response.data.data.dgi_qr)
      }
      console.log(response.data.data.order_code);
      setOrder_code_register(response.data.data.order_code)
      setDataNoteCredit(true);


      {/*setTimeout(() => {
        clearShoppingCart()
      }, 120000);*/}


    } catch (err) {
      console.log(err);
    }
  }


  //selected payment
  const selectPayment = (e, payment) => {
    e.preventDefault();
    selectprocessPayment(payment)
  }


  const selectprocessPayment = (payment) => {
    if (statusComunicationVending == true) {
      if (payment.payment == "Yappy") {
        handlerYappy(payment)
      } else if (payment.payment == "Yappy QR") {
        setPaymentInfo({ payment: payment.payment, payment_id: payment.payment_id, payment_data: payment })
        GenerateYappyTransaction(payment)
      } else {
        paymentValid(payment)
      }
    } else {
      setShowModaloutService(true)
    }
  }

  //Register order
  const registerOrder = async (payment_id, EMVStreamResponseReponse, payment) => {

    console.log(EMVStreamResponseReponse);
    console.log(payment);
    let token = localStorage.getItem('x-access-token')

    let json = {
      "EMVStreamResponse": EMVStreamResponseReponse,
      "client_name": data.name,
      "id_Client": 0,
      "ruc": data.ruc,
      "dv": data.dv,
      "localizador": 0,
      "client_phone": "0",
      "dgi_qr": "0",
      "Orden": shoppingCart,
      "Descuento": invoiceTotalDiscount,
      "Total": invoiceTotal,
      "itbms": invoiceTotalItbms,
      "taxes": taxesArrayAll,
      "subtotal": invoiceSubtotal,
      "machine_id": machine.id,
      "branchoffice_id": branchoffice.id,
      "tipoOrden": "comer aqui",
      "paymethod": payment_id,
      "paymenthod_integration_id": payment.integration.id
    }
    try {
      localStorage.setItem('countNotification', 0);
      const response = await OrderNumberFetchAPI.registerOrder(json, token)
      setOrder_code_register(response.data.data.order_code)
      setTransaction_code_register(response.data.data.transaction_code)
      getOrder(response.data.data.transaction_code);
      shoppingCartDispense(payment.payment, response.data.data.order_code);

    } catch (err) {
      console.log(err);
      setShowModalDispense(false);
      ErrorAlert('', 'Error de registro', 'error');
    }
  }


  //----------------------------yappy qr------------------------------------------

  // get order number in process
  const getOrderNumber = async () => {
    //let branchoffice_vending =  JSON.parse(localStorage.getItem("branchoffice_vending"));
    try {
      const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice.id, token)
      //console.log(response);
      setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
    } catch (err) {
      console.log(err);
    }
  }


  //decimal reduction
  const decimalReduction = (value) => {

    let string = value.toString()
    let arrayString = string.split(".");
    let newDecimalString = []
    let sample = ''
    if (arrayString[1] != undefined) {
      newDecimalString = arrayString[1].slice(0, 2);
      sample = arrayString[0] + "." + newDecimalString
      console.log("Hice la reduccion");
    } else {
      console.log("Array: " + arrayString);
      console.log("Decimal: " + newDecimalString);
      console.log("Nuevo valor: " + sample);
      sample = string;
    }

    let endValue = parseFloat(sample)
    return endValue
  }

  //genrate yappy transaction
  const GenerateYappyTransaction = async (payment) => {
    //setModalYappyQr(true)
    setDataInfoPaymentSelect(payment.payment)
    LoadingAlert()

    let token = localStorage.getItem('x-access-token');
    let machine = JSON.parse(localStorage.getItem('machine_vending'));
    let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
    let data = {
      "machine_id": machine.id,
      "total": decimalReduction(invoiceTotal).toString(),
      "subtotal": decimalReduction(invoiceTotal).toString(),
      "tax": "0",
      "discount": "0",
      "transaction_code": orderNumber,
      "description": "Smartpay - orden:" + orderNumber,
      "params": payment.params
    }
    try {
      const response = await YappyFetchAPI.newYappyQr(data, token)
      console.log(response);


      ErrorAlert("", "Todo listo para que realices el pago", "info")
      setDataYappy(response.data);

      oledQrYappy(response.data.yappy_transaction.body.hash)
      setDataHash(response.data.yappy_transaction.body.hash)
      console.log(response.data.yappy_transaction.body.hash);
      setModalYappyQr(true)
      setStatusRequestYappy(true)
      //getYappyTransaction(response.data)

    } catch (err) {
      ErrorAlert("", "Lo sentimos yappy no esta disponible", "warning")
      console.log(err);
    }
  }

  const hadleCancelYappy = (text) => {

    if (text != undefined) {
      setPaymentApproval({ status: 2, description: text });
    } else {
      setPaymentApproval({ status: 2, description: "CANCELADO" });
    }

    setModalYappyQr(false);
    setStatusRequestYappy(false)
    console.log("cancele yappy");

    setTimeout(() => {
      setPaymentInfo({ payment: "", payment_id: 0 })

      setPaymentApproval({ status: 0, description: "Procesando pago..." })
    }, 3000);
  }

  const getYappyTransaction = async () => {
    console.log(dataYappy);
    let token = localStorage.getItem('x-access-token');

    try {
      let yappy_transaction_id = dataYappy.yappy_transaction.body.transactionId
      console.log(yappy_transaction_id);

      const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);
      console.log(response);

      let status = response.data.yappy_transaction.body.status;

      switch (status) {
        case 'PENDING':
          console.log("Transaccion pendiente");
          break;

        case 'COMPLETED':
          console.log("Transaccion aprobada");
          oledsuccess()
          setVoucher_Order("0")
          setModalYappyQr(false)
          setStatusRequestYappy(false)
          setShowModalDispense(true);
          setShowModalPayment(false)
          setPaymentApproval({ status: 1, description: "APROBADO" })
          registerOrder(paymentInfo.payment_id, 0, paymentInfo.payment_data);

          break;

        case 'DECLINED':
          olederror('        DECLINED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion declinda");
          setDataHash("")
          hadleCancelYappy('DECLINED')

          break;

        case 'EXPIRED':
          olederror('        EXPIRED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion expirada");
          setDataHash("");
          hadleCancelYappy('EXPIRED')
          break;

        case 'FAILED':
          olederror('        FAILED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion fallida");
          setDataHash("")
          hadleCancelYappy('FAILED')
          break;

        case 'VOIDED':
          olederror('        VOIDED')
          setPaymentInfo({ payment: "", payment_id: 0 })
          console.log("Transaccion Eliminada");
          setDataHash("")
          hadleCancelYappy('VOIDED')
          break;

      }

    } catch (err) {
      console.log(err);
      setPaymentInfo({ payment: "", payment_id: 0 })
      setDataHash("")
      hadleCancelYappy("ERROR DE CONSULTA")
      olederror()

    }

  }
  //---------------================================================================

  //get order
  const getOrder = async (transaction_code) => {
    console.log("Estoy en consultar orden");
    try {
      const response = await FilterOrderFetchAPI.getOrder(transaction_code, branchoffice.code, business.code, token)

      console.log(response.data.EMVStreamResponse.voucher);
      setVoucher_Order(response.data.EMVStreamResponse.voucher)
      formatVoucherString(response.data.EMVStreamResponse.voucher)

    } catch (err) {
      console.log(err);
    }
  }

  //Function action yappy
  const handlerYappy = (payment) => {
    setPaymentSelect(payment.payment)
    setShowModalYappy(true)
    setSelectPaymentYappy(payment)
  }



  //Function to view the payments
  const viewPayment = () => {
    const listPayments = payments.map((payment) => (
      <>
        <div className="col-4 col-md-4 col-lg-4 mt-3" onClick={async (e) => (getOrderNumber(), selectPayment(e, payment))}>
          <input type="radio" className="btn-check"
            name="option_payments" id={payment.payment + "payment"}
          />
          <label className="btn btn-pay-meth " htmlFor={payment.payment + "payment"}>
            <img src={payment.img}

              className="img-pay-group  rounded px-4"
              alt="Imagen Payments"
              width="210px"
              height="210px"
            />
            <small className='title-small-pay px-3'>
              {payment.payment}
            </small>
          </label>
        </div>
      </>
    ))
    return listPayments
  }

  //get divices of the machine
  const getdivise = async () => {
    try {
      const response = await DivicesFechtAPI.getDivices(machine.id, token)
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  const sendMessage = async () => {
    console.log("entre aqui");
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
      console.log(response_machine);
      let data = {
        "notification_type": "smartpay_notification",
        "branchoffice_id": branchoffice.id,
        "business_id": business.id,
        "phone_to_send": branchoffice.branchoffice_support_phone,
        "header_text": "Error de despacho",
        "message_body": [{ "text": response_machine.data.data.machine_name + "de la empresa " + business.name + " no se despacho correctamente el producto en la posicion: " + "X:" + shoppingCart[0].X + ", Y:" + shoppingCart[0].Y + " de la orden: " + order_code_register + ", metodo de pago: " + paymentSelect }]
      }

      console.log("entre y envie mensaje");
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
    } catch (err) {
      console.log("entre error en mensaje");
      console.log(err);
    }
  }

  const sendMessage2 = async (payment, order_code) => {
    machine = JSON.parse(localStorage.getItem('machine_vending'))
    branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
    business = JSON.parse(localStorage.getItem('business_vending'))


    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token);

      let data = {
        "notification_type": "smartpay_notification",
        "branchoffice_id": branchoffice.id,
        "business_id": business.id,
        "phone_to_send": branchoffice.branchoffice_support_phone,
        "header_text": "Error de comunicacion",
        "message_body": [{ "text": response_machine.data.data.machine_name + " de la empresa " + business.name + " no se despacho correctamente el producto en la posicion: " + "X:" + shoppingCart[0].X + ", Y:" + shoppingCart[0].Y }, { "text": "de la orden: " + order_code + ", metodo de pago: " + payment }]
      }
      console.log(data);
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  //dispatch sequence
  let intervalNewOrder = 0
  const shoppingCartDispense = async (payment, order_code) => {
    console.log(payment + " " + order_code);

    let tempDispense = new Array;
    let statusValid = 0;
    //setShowModalDispense(true);
    for (let item = 0; item < shoppingCart.length; item++) {
      let res = 0;
      if (shoppingCart[item].amount > 1) {
        for (let i = 0; i < shoppingCart[item].amount; i++) {
          if (machine.dispense == "Vending chine Generica") {
            res = await dispense(shoppingCart[item].X, shoppingCart[item].Y);
          } else if (machine.dispense == "Vending gringa generica") {
            res = await dispenseSnakky(shoppingCart[item].X)
            setTimeout(async () => {
              await dispenseSnakky(shoppingCart[item].Y)
            }, 100)
          }
          tempDispense.push(shoppingCart[item]);
          updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, (shoppingCart[item].stock - 1))
          setItemReadyDispense(tempDispense);
          setEventDispense(1);
        }
        finishDispensed()
      } else {

        let status = 0
        let newItemDispense = shoppingCart[item]
        try {

          if (machine.machine_subtype_code == "VCG") {
            res = await dispense(shoppingCart[item].X, shoppingCart[item].Y);
            status = res.data.data
          } else if (machine.machine_subtype_code == "VGG") {
            //res = await dispenseSnakky('1')
            await dispenseSnakky(shoppingCart[item].X)


            /* setTimeout(async () => {
               await dispenseSnakky(shoppingCart[item].X)
 
             }, 100)*/

            setTimeout(async () => {
              await dispenseSnakky(shoppingCart[item].Y)
              status = 0
            }, 300)
          }

        } catch (err) {
          status = 1
        }
        if (status == 0) {
          updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, (shoppingCart[item].stock - shoppingCart[item].amount))
          newItemDispense.status = "success"
          setItemReadyDispense([...itemReadyDispense, newItemDispense]);
          tempDispense.push(shoppingCart[item]);
          setItemReadyDispense(tempDispense);
          setEventDispense(1);
          finishDispensed()
        } else {
          console.log("entre a error");
          sendMessage2(payment, order_code);
          updateStockItem(shoppingCart[item].group_id, shoppingCart[item].position_id, 0)
          newItemDispense.status = "error"
          setItemReadyDispense([...itemReadyDispense, newItemDispense]);
          tempDispense.push(newItemDispense);
          setItemReadyDispense(tempDispense);
          setEventDispense(1);
          setStatusError(true)

          statusValid = 1


        }
      }
    }

    if (statusValid == 1) {
      setStatusError(true)

    }

    function finishDispensed() {
      {/*setTimeout(() => {
        setReady(true);
      }, 3000);*/}
      setReady(true);

      if (machine.machine_subtype_code === "VGG") {
        setTimeout(() => {
          clearShoppingCart()
        }, 3000);
      } else {
        setShowModalConfirmation(true);
        intervalNewOrder = setInterval(() => {
          clearShoppingCart()
        }, 30000);

        {/*setTimeout(() => {
          clearShoppingCart();
        }, 5000);*/}
      }
    }
  }

  //================OLED SCREEN=======================

  const oledResponse = (status, description) => {
    oledComunication(0, '2,0')
    setTimeout(() => {
      oledComunication(0, '2,1,1,50,20,' + status)
    }, 50);

    setTimeout(() => {
      oledComunication(0, '2,1,1,0,30,' + description)
    }, 100);
  }

  const oledsuccess = () => {
    if (machine.machine_subtype_code === 'VGG') {
      setTimeout(() => {
        oledResponse('EXITO', ' Retire su producto')

        setTimeout(() => {
          oledScreenPayments()
        }, 1000);
      }, 500);
    }
  }

  const olederror = (txt) => {
    if (machine.machine_subtype_code === 'VGG') {
      let description='     Error de pago'
      if(txt != undefined){
        description = txt
      }
      setTimeout(() => {
        oledResponse('ERROR', description)

        setTimeout(() => {
          oledScreenPayments()
        }, 1000);
      }, 500);
    }
  }


  const oledQrYappy = (hash) =>{
   
    if (machine.machine_subtype_code === 'VGG') {
      let jsonYappy = [{"comandaVendType":3, "vendingCode":"2,3,"+hash+", Yappy QR,  $"+invoiceTotal+", , ,0.CANCELAR"}]


      oledComunication(0, '2,0')
      setTimeout(() => {
        console.log("tengo el hash:" + hash);
        console.log(jsonYappy)
        oledComunication(3,'', jsonYappy)
      }, 50);

    }
  }
  //==================================================

  const refresh = () => {

    if (machine.machine_subtype_code === 'VGG') {
      oledComunication(0, '2,0')

      setTimeout(() => {
        oledComunication(0, '2,1,1,35,30,Preparando')
      }, 50);

      setTimeout(() => {
        oledComunication(0, '2,1,1,40,40,maquina...')
      }, 100);


      setTimeout(() => {
        window.location.reload(true);
      }, 150);

    } else {
      window.location.reload(true);
    }
  }

  //Function to clear the shopping cart
  const clearShoppingCart = () => {
    refresh();
    clearInterval(intervalNewOrder)
    setItemReadyDispense([]);
    setShowModalDispense(false);
    setReady(false);
    setStatusError(false);
    setShowModalConfirmation(false)
    setDataNoteCredit(false)


    //Clear params voucher 
    setVoucher_Order("0")

    //Clear and reload all request
    //setEventHandlerMenu(1)

    //We set the shopping cart to empty
    setShoppingCart([]);
    //We close the modal
    setShowModalPayment(false)

    //We set the handler to recharge the data
    setEventHandlerShoppingCart(1);
    //We close the modal
    setShowModalPayment(false);

    setStatusScreen(false);


  };

  //action dispese in the vending machine
  const dispense = async (X, Y) => {
    return new Promise(async (resolve, reject) => {
      let json = { funcion: "DESPACHAR", datos: [{ X: X.toString(), Y: Y.toString() }] }
      try {
        const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
        const response = await VendingFetchAPI.operation(response_machine.data.data.android_ip, json)

        resolve(response);
        return (response);
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });
  }


  //action dispese in the vending machine
  const dispenseSnakky = async (key) => {
    return new Promise(async (resolve, reject) => {
      try {

        let data = [{ "comandaVendType": 0, "vendingCode": "1," + key }]
        const response = await VendingSnakkyFetchAPI.keypad(data)

        resolve(response);
        return (response);
      } catch (err) {
        reject(err);
        console.log(err);
      }
    });
  }


  //update and stock product in the position
  const updateStockItem = async (group_id, position_id, amount) => {
    console.log("Actualizar la cantidad del producto");
    let item = {
      "group_id": group_id,
      "position_id": position_id,
      "amount": amount
    }

    try {
      const response = await PositionFetchAPI.updatePositionToGroup(machine.id, item, token)
      console.log(response);

      let dataNowGroupsAndPositions = []

      if (localStorage.getItem('GroupsByMachine') != undefined || localStorage.getItem('GroupsByMachine') != '') {
        dataNowGroupsAndPositions = JSON.parse(localStorage.getItem('GroupsByMachine'))
        console.log("intente actualizar el item");


        dataNowGroupsAndPositions.forEach(position => {
          shoppingCart.forEach(group => {
            if (group.position_id === position.position_id) {
              position.amount = amount
            }
          })
        });


        localStorage.setItem("GroupsByMachine", JSON.stringify(dataNowGroupsAndPositions))
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    //infoMachineLocal();
    getPaymentsMethod();
    getdivise();
  }, []);

  useEffect(() => {
    console.log(shoppingCart);
    console.log(invoiceTotal);
    if (shoppingCart.length > 0) {
      setItem({ name: shoppingCart[0].name, price: shoppingCart[0].price, img: shoppingCart[0].img })
    }
  }, [shoppingCart]);

  useEffect(() => {

    setEventDispense(0)
  }, [eventDispense]);

  useEffect(() => {
    let timer = 0
    if (statusRequestYappy == true) {
      console.log("enter en el intervalo");
      timer = setInterval(async () => getYappyTransaction(), 5000); //INTERVAL GET YAPPY STATUS TRANSACTION

      return () => clearInterval(timer)
    } else {
      return () => clearInterval(timer) //CLEAR INTERVAL
    }

  }, [statusRequestYappy]);

  return (
    <>

      <Modal
        show={showModalPayment}
        fullscreen
        onHide={() => setShowModalPayment(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="flex-grow-1 flex-shrink-0" onClick={() => (inputRefLog.current.focus())}>
            <section className='d-flex align-items-start flex-column bd-highlight'>
              <div className='bd-highlight  w-100'>
                <h2 className='title-modal-cart text-center'>
                  <i className="uil uil-transaction  icon-button"></i>
                  Pago
                </h2>
              </div>
              <h4 className='title-modal-pay '>Producto a pagar</h4>
              {/* <div className='w-100 mt-5'>
                  <div className="d-flex alight-item-center mt-5">
                    <div>
                      
                      <div className='row'>

                        <div className='col-6'></div>
                        <div className='col-6'></div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mt-4 shadow rounded bg-white p-4">
                        <div>

                        </div>
                        <div className="flex-grow-1 ms-3 name-product-cart-end fw-bold">
                       
                          <br />

                        </div>
                      </div>
                    </div>

                  </div>
                </div> */}
              <div className='d-flex  mb-3 w-100'>
                <div className="met-pay d-flex flex-column   bd-highlight">
                  <div className=" p-2 bd-highlight my-3">
                    <div className="d-flex justify-content-center align-items-center bg-white">
                      <div className="flex-shrink-0">
                        <img src={item.img}
                          alt="..."
                          className='img-pay-meth rounded border'
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h2 className='display-2 fw-bold'>{item.name}</h2>                      </div>
                    </div>
                    {/* <div className='d-flex justify-content-center'>
                      <img src={item.img} 
                        alt="..."
                        className='img-pay-meth rounded border'
                      />
                    </div> */}
                  </div>
                  {/* <div className="p-2 bd-highlight text-center">
                    <h2 className='display-2 fw-bold'>{item.name} con queso y parmesano frito </h2>
                  </div> */}
                  <hr className="divider" />
                  <div className="p-2 bd-highlight w-100">
                    <div className="mb-0">
                      <div className="d-flex justify-content-between my-4">
                        <small className="text-muted display-4 fw-bold"> Descuento: </small>
                        <span className="text-warning text-secondary display-4">
                          <span className="px-2 ">- $</span>
                          <strong className='fw-bold'> {invoiceTotalDiscount.toFixed(2)} </strong>
                        </span>
                      </div>
                      <div className="d-flex justify-content-between my-5">
                        <small className="text-muted display-4 fw-bold"> Subtotal: </small>
                        <span className="text-black text-secondary display-4">
                          <span className="px-2 ">$
                          </span>{parseFloat(item.price).toFixed(2)}
                        </span>
                      </div>


                      {taxesArrayAll.map((tax) => (
                        <>
                          {tax.total > 0 ?
                            <div className="d-flex justify-content-between my-5">
                              <small className=" text-muted display-4 fw-bold"> Impuesto {tax.tax}:</small>
                              <span className="text-black text-secondary display-4">
                                <span className="px-2 ">$
                                </span>{tax.total.toFixed(2)}
                              </span>
                            </div>
                            : <></>}
                        </>
                      ))}


                      <div className="d-flex justify-content-between total-shopping mt-5">
                        <strong className="text-black fw-bold display-4">
                          Total
                          <small className='text-muted'>(+ITBMS)</small>:
                        </strong>
                        <span className="text-black fw-bold display-4">
                          <span className="px-2 ">$
                          </span>{invoiceTotal.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr className="divider" />
                  <div className="p-2 bd-highlight w-100">
                    <div className="">
                      <h4 className='title-modal-pay'>Seleccione una forma de pago</h4>
                      <div className='mt-4 bg-white d-flex alight-item-center justify-content-center  '>
                        <div className='row mb-3'>
                          {viewPayment()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {/* <div className='bd-highlight mb-auto met-pay w-100 mt-5 bg-light'>
                <h3 className='text-center title-modal-pay text-muted'>Datos del Cliente:</h3>
                <div className="d-flex alight-item-center justify-content-center  my-5">
                  <div>
                    <h3 className='text-center title-modal-cart'>Cliente:{data.name}</h3>
                    <p className='text-center title-modal-cart'>Ruc: {data.ruc} DV: {data.dv}</p>
                    <div className='d-flex align-item-center justify-content-end mt-5'>
                      <button className='link-primary display-5 fw-bold' onClick={() => (handleDataClient())}>
                        <i className="uil uil-edit-alt"></i> Editar datos
                      </button>
                    </div>
                  </div>
                </div>
              </div>   */}
            </section>

            {/*Input discunt*/}
            <input id="inputCodeQrDiscount" className='vending-input-qr' ref={inputRefLog} inputMode="none" type="text" onChange={(e) => handleChange(e.target.value)} autoFocus></input>

          </div>

        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start' onClick={() => (inputRefLog.current.focus())}>
          <button className='btn btn-outline-primary rounded-sp border border-primary px-3 py-2 shadow-sm' onClick={() => (setShowModalPayment(false), clearShoppingCart())}>
            <span className='display-1 fw-bold text-center'>
              <i className="uil uil-arrow-left icon-button"><h1>Cancelar</h1></i>
            </span>
          </button>
        </Modal.Footer>
      </Modal >

      {/*Process payment modal */}
      <Modal
        show={showModalPaymentProcess}
        fullscreen
        onHide={() => setShowModalPaymentProcess(false)}
      >
        <Modal.Body>
          <div className="flex-grow-1 flex-shrink-0">
            <section className='d-flex align-items-start flex-column bd-highlight'>
              <div className='bd-highlight  w-100 border-bottom'>
                <h2 className='title-modal-cart text-center'>
                  <i className="uil uil-transaction  icon-button"></i>
                  Procesando pago
                </h2>
              </div>
              <div className='met-pay bd-highlight  w-100 mt-5'>
                <div className="d-flex alight-item-center justify-content-center mt-5">
                  <div>
                    <h3 className=' text-muted display-1 fw-bold'>Saldo a pagar:</h3>
                    <div className='bg-light rounded-sp p-3 '>
                      <h2 className='text-center display-1'>${invoiceTotal.toFixed(2)}</h2>
                    </div>
                  </div>
                </div>
                <RenderIf isTrue={dataInfoPaymentSelect == "Yappy"}>
                  <div className=''>
                    <h3 className=' text-center display-1 fw-bold m-5'>Revisa en PENDIENTES de yappy.</h3>
                  </div>
                </RenderIf>
              </div>
              <div className='bd-highlight met-pay w-100 mt-5'>
                <div className=" d-flex alight-item-center justify-content-center  mt-5">
                  <div>
                    <div className='mt-2'>
                      <div className='row'>
                        <RenderIf isTrue={paymentApproval.status == 1}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={checkImg} height="500px" alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={paymentApproval.status == 2}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={errorImg} height="500px" alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <RenderIf isTrue={paymentApproval.status == 0}>
                          <div className=" d-flex alight-item-center justify-content-center">
                            <img src={loadingImg} height="500px" alt="Imagen Payments" ></img>
                          </div>
                        </RenderIf>
                        <h3 className='text-center display-2 text-muted'>{paymentApproval.description}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </Modal.Body>
      </Modal >

      <Modal
        show={showModalDatos}
        fullscreen
        onHide={() => setShowModalDatos(false)}
        aria-labelledby="example-custom-modal-styling-title">

        <Modal.Body>
          <div className='bd-highlight'>
            <h2 className='title-modal-cart text-center'>{title}</h2>
          </div>

          <Keyboard displayKeyboard={displayKeyboard} setDisplayKeyboard={setDisplayKeyboard} />

          <div className='bd-highlight w-100 row'>
            <div className='col-md-6'><button type="button" className=" btn btn-lg btn-vending w-100 m-2" onClick={() => (setShowModalDatos(false))}>
              Volver
            </button></div>
            <div className='col-md-6'><button type="button" className=" btn btn-lg btn-vending w-100 m-2" onClick={() => (saveDataClient())} >
              siguiente
            </button></div>
          </div>
        </Modal.Body>
      </Modal>

      <Vendingoutservice showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} setEventHandlerMenu={setEventHandlerMenu} clearShoppingCart={clearShoppingCart} status={1}></Vendingoutservice>
      <VendingDispensed itemReadyDispense={itemReadyDispense} showModalDispense={showModalDispense} setShowModalDispense={setShowModalDispense} ready={ready} statusError={statusError} setStatusError={setStatusError} voucher_Order={voucher_Order} dataInfoPaymentSelect={dataInfoPaymentSelect} setEventHandlerMenu={setEventHandlerMenu} order_code_register={order_code_register} clearShoppingCart={clearShoppingCart} voidCreditDebit={voidCreditDebit} dataNoteCredit={dataNoteCredit} setDataRutaQr={setDataRutaQr} dataRutaQr={dataRutaQr} shoppingCart={shoppingCart} registerOrderTempReturn={registerOrderTempReturn} ></VendingDispensed>
      <VendingModalConfirmation clearShoppingCart={clearShoppingCart} orderCode={order_code_register} total={invoiceTotal} orderNumber={transaction_code_register} showModalConfirmation={showModalConfirmation} setShowModalConfirmation={setShowModalConfirmation} voucher_Order={voucher_Order} dataInfoPaymentSelect={dataInfoPaymentSelect}></VendingModalConfirmation>
      <VendingModalYappy showModalYappy={showModalYappy} setShowModalYappy={setShowModalYappy} selectPaymentYappy={selectPaymentYappy} paymentValidYappy={paymentValidYappy}></VendingModalYappy>
      <ModalYappyQr paymentInfo={paymentInfo} modalYappyQr={modalYappyQr} setModalYappyQr={setModalYappyQr} dataYappy={dataYappy} setDataHash={setDataHash} dataHash={dataHash} hadleCancelYappy={hadleCancelYappy} invoiceTotal={invoiceTotal} orderNumber={orderNumber}></ModalYappyQr>


    </>
  )
}

export default VendingMetPayments