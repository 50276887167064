import React, { useState, useEffect } from 'react'
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import stand from '../../assets/img/vending/stand.png'
import { RenderIf } from '../../components/utils/RenderIf';
import VendingModalCart from './VendingModalCart';
import Vendingoutservice from './Vendingoutservice';
import { Modal } from 'react-bootstrap';
import { BiCartDownload } from 'react-icons/bi';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
function VendingModalProduct({ selectGroup, setShoppingCart, shoppingCart, showModalProduct, setShowModalProduct, setShowModalShopingCart, setEventHandlerMenu, showModalShopingCart, dataClient, setDataClient, groups, setSelectGroup, getGroupById, showModalPayment, setShowModalPayment, setStatusScreen, statusScreen, getTemp, statusComunicationVending, amount, setAmount,
  showModaloutService, setShowModaloutService, orderNumber, setOrderNumber, addProduct, getOrderNumber, payments, setPayments, oledScreenPayments
 }) {
  //const [showModalPayment, setShowModalPayment] = useState(false);
  const [eventHandler, setEventHandler] = useState(0)
  const [eventHandlerLoader, setEventHandlerLoader] = useState(0)
  let business = localStorage.getItem('business_vending');
  let businessCode = ""

  if (business != null && business != '' && business != undefined) {
    business = JSON.parse(localStorage.getItem('business_vending'));
    businessCode = business.code
    console.log(business.code)
  }

  //console.log(selectGroup);
  //increment amount
  const buttonPlus = () => setAmount(amount + 1);
  //decrement amount
  const buttonDecrease = () => {
    if (amount > 1) {
      setAmount(amount - 1)
    }
  }


  //Next Product
  const nextProduct = () => {

    let index = selectGroup.index
    const array = groups.filter(producto => producto.name !== null && producto.amount > 0);

    const productNoNulls = [];

    array.forEach(p => {
      if (productNoNulls.findIndex(item => item.group_id === p.group_id) === -1) {
        // No existe; al detectar que no existe el mismo nombre, "la copiamos"
        productNoNulls.push(p);
      }
    });

    ordenarAsc(productNoNulls, 'positionX')
    let nextIndex = (index + 1)

    getGroupById(productNoNulls[nextIndex].group_id, productNoNulls[nextIndex].positionX, productNoNulls[nextIndex].positionY, productNoNulls[nextIndex].amount, productNoNulls[nextIndex].position_id, nextIndex, productNoNulls[nextIndex].detail_group)

    setEventHandlerLoader(1);
  }


  function ordenarAsc(p_array_json, p_key) {
    let newArray = p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });


    return (newArray.reverse())
  }


  const afterProduct = () => {

    let index = selectGroup.index
    const array = groups.filter(producto => producto.name !== null && producto.amount > 0);

    const productNoNulls = [];

    array.forEach(p => {
      if (productNoNulls.findIndex(item => item.group_id === p.group_id) === -1) {
        // No existe; al detectar que no existe el mismo nombre, "la copiamos"
        productNoNulls.push(p);
      }
    });

    ordenarAsc(productNoNulls, 'positionX')
    let nextIndex = (index - 1)
    getGroupById(productNoNulls[nextIndex].group_id, productNoNulls[nextIndex].positionX, productNoNulls[nextIndex].positionY, productNoNulls[nextIndex].amount, productNoNulls[nextIndex].position_id, nextIndex, productNoNulls[nextIndex].detail_group)

    setEventHandlerLoader(1);
  }


  //Hook to recharge the component
  useEffect(() => {
    setShoppingCart([...shoppingCart]);
    setEventHandler(0);

  }, [eventHandler]);

  //Hook to recharge the component
  useEffect(() => {

    setEventHandlerLoader(0);
  }, [eventHandlerLoader]);

  return (
    <>
      <Modal
        show={showModalProduct}
        fullscreen
        onHide={() => setShowModalProduct(false)}

      >
        <Modal.Body className='m-0 p-0'>
          <div className='vending'>
            <h2 className='text-center fw-bold mb-0 title-modal-categories  pt-2 mt-5'>
              Producto
            </h2>

            <div className='product-stand position-relative'>
              <img src={stand} className=' background-image img-stand' alt="Stand" />
              <div className='d-flex justify-content-center'>
                <img src={selectGroup.img} className='foreground-image  img-modal-product' alt="" />
              </div>


              <div className="buttons-steps w-100">
                <div className='d-flex justify-content-between'>
                  <button className="round-button shadow-sm" onClick={() => (afterProduct())}>
                    <span className='text-center modal-title-product'>
                      <i className="uil uil-arrow-circle-left"></i>
                    </span>
                  </button>
                  <button className="round-button shadow-sm" onClick={() => (nextProduct())}>
                    <span className='text-center modal-title-product'>
                      <i className="uil uil-arrow-circle-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className='buttons-steps'>
            </div>

            <h3 className='text-center modal-title-product'>
              {selectGroup.name}
            </h3>
            <div>
              <h4 className='text-center modal-price-product '>$ {(parseFloat(selectGroup.price) - parseFloat(selectGroup.product_discount)).toFixed(2)}</h4>
            </div>
            <div className="mt-5 " hidden>
              <span className='px-1 number-counter'>
                <button className="round-button" onClick={() => (buttonDecrease())}>
                  <span className="minus"></span>
                </button>
                <p>{amount}</p>
                <button className="round-button" onClick={() => (buttonPlus())}>
                  <span className="plus"></span>
                </button>
              </span>
            </div>

            <div className='d-flex justify-content-center g-3 mt-5'>
              {/* <button className='btn btn-primary btn-vending-product btn-menu-vending mx-3' onClick={() => { setShowModalProduct(false) }}><i className="uil uil-arrow-left"></i> Volver </button> */}
              <RenderIf isTrue={selectGroup.stock > 0}>
                <button className='btn btn-success btn-lg mt-1 px-5 btn-vending btn-vending-success ' onClick={() => (addProduct())} >
                  <i className="uil uil-shopping-cart-alt  display-1"></i>
                  <span className='display-1 fw-bold'> Comprar </span>
                </button>
              </RenderIf>
              <RenderIf isTrue={selectGroup.stock == 0 || selectGroup.stock < 0}>
                <button type="button" className="btn btn-outline-secondary btn-vending-product btn-menu-vending mx-3 disabled"> <i className="uil uil-sad-squint"></i> Agotado</button>
              </RenderIf>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start'>
          <button
            data-bs-toggle="tooltip" data-bs-placement="right" title="Volver al menú"
            className='btn btn-outline-primary rounded-sp border border-primary px-3 py-2 shadow-sm'
            onClick={() => { setShowModalProduct(false); setStatusScreen({ screen: "menu" }); setShoppingCart([]) }}

          >
            <span className='display-1 fw-bold'>
              <i className="uil uil-arrow-left icon-button"></i>
              {/* Regresar */}
            </span>
          </button>
        </Modal.Footer>
      </Modal>



      {/*----------------------------------------*/}
      <VendingModalCart setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} showModalShopingCart={showModalShopingCart} setShowModalShopingCart={setShowModalShopingCart} eventHandler={eventHandler} dataClient={dataClient} setDataClient={setDataClient} showModalPayment={showModalPayment} setShowModalPayment={setShowModalPayment} setEventHandlerMenu={setEventHandlerMenu} setStatusScreen={setStatusScreen} statusComunicationVending={statusComunicationVending} groups={groups} orderNumber={orderNumber} setOrderNumber={setOrderNumber} payments={payments} setPayments={setPayments} oledScreenPayments={oledScreenPayments} />
      <Vendingoutservice showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} setEventHandlerMenu={setEventHandlerMenu} clearShoppingCart status={0}></Vendingoutservice>


    </>
  )
}

export default VendingModalProduct