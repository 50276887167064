import React, { useState, useEffect, useRef } from 'react'
import qr_img from '../../assets/img/vending/qr.png'
import VendingModalKeyCode from '../Vending/VendingModalKeyCode'
import VendingStock from './VendingStock';
import { Modal } from 'react-bootstrap';
import scanQR from './img/scanqr.jpg'
import scanQR2 from './img/scanyourqr.gif'

import ErrorAlert from '../../components/Alerts/ErrorAlert';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

//api
import { AuthFetchAPI } from '../../api/Auth';

function VendingModalOperator({ logo, showModalAuthOperator, setShowModalAuthOperator, humidity, temperature, setShowModalStock, showModalStock, setEventHandlerMenu, showModalKeyCode, setShowModalKeyCode, setStatusScreen }) {
    const [codeSesion, setCodeSesion] = useState({
        business_code: "0000",
        branchoffice_code: "0000",
        machine_code: "0000",
    });
    const inputRef = useRef(null);

   




    const authOperator = () => {

        let business = JSON.parse(localStorage.getItem('business_vending'));
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'));
        let machine = JSON.parse(localStorage.getItem('machine_vending'));

        if (business.code == codeSesion.business_code) {
            if (branchoffice.code == codeSesion.branchoffice_code) {
                if (machine.code == codeSesion.machine_code) {
                    setStatusScreen(true);
                    ErrorAlert("Operador autenticado", "Acceso permitido", "success");
                    setShowModalAuthOperator(false);
                    setEventHandlerMenu(1);
                    setShowModalStock(true);

                } else {
                    ErrorAlert("Acceso denegado", "Codigo de maquina incorrecto", "error");
                    setShowModalAuthOperator(false);
                    document.getElementById("inputCodeQrOperator").value = ""

                }
            } else {
                ErrorAlert("Acceso denegado", "Codigo de sucursal incorrecto", "error");
                setShowModalAuthOperator(false);
                document.getElementById("inputCodeQrOperator").value = ""
            }
        } else {
            ErrorAlert("Acceso denegado", "Codigo de empresa incorrecto", "error");
            setShowModalAuthOperator(false);
            document.getElementById("inputCodeQrOperator").value = ""
        }
        setShowModalKeyCode(false)
        setCodeSesion({ business_code: "0000", branchoffice_code: "0000", machine_code: "0000", });

    }


    const handleChange = (e) => {
        if (e.key == "Enter") {
            LoadingAlert()
            let inputValue = e.target.value.toUpperCase();
            let arrayCodeString = inputValue.split("X");
            console.log(arrayCodeString);

            codeSesion.business_code = parseInt(arrayCodeString[3])
            codeSesion.branchoffice_code = parseInt(arrayCodeString[2])
            codeSesion.machine_code = parseInt(arrayCodeString[1])
            setCodeSesion(codeSesion)

            authOperator()
        }

    }

    function actualizar() {
        window.location.reload();
    }

    return (
        <>
            <div className="branding-mark position-absolute top-0 start-50 
                        translate-middle mt-5"
                //onClick={() => (setShowModalStock(true))} 
                onClick={() => (setShowModalAuthOperator(true), setTimeout(() => { inputRef.current.focus() }, 3000))}
            >
                <div className='mt-5 pt-5'><img src={logo} width={'400px'} ></img></div>
                <h1 className='text-center'>
                    {/* Ordene aquí */}
                </h1>                
            </div>



            <Modal
                show={showModalAuthOperator}
                fullscreen
                onHide={() => setShowModalAuthOperator(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body>
                    {/* 1080 X 1920 */}
                    <main className='vending' onClick={() => (inputRef.current.focus())} >
                        <small className='display-4 text-muted opacity-25' onClick={() => actualizar()}>Abastecimiento</small>

                        <div className="d-flex align-items-center flex-column bd-highlight">
                            <div className='vendindg-title  bd-highlight  pt-1'>
                                <h2 className='fw-bold mb-0 text-center text-title-help'>
                                    Validación de Usuario
                                </h2>
                            </div>
                            <div className='container  mt-5'>
                                <div className='vendindg-message  bd-highlight  py-2'>
                                    <h2 className="mb-0 text-center text-title-help">
                                        Por favor, coloque el código QR en el escáner de la máquina
                                    </h2>
                                </div>
                                <div className='vendindg-img bd-highlight my-5' >
                                    <div className="d-flex justify-content-center  align-self-center ">
                                        <img src={scanQR2} alt="QR Img" className='vending-qr' loading='lazy' />
                                    </div>
                                </div>
                                <div className='vending-button bd-highlight'>
                                    <div className="d-flex justify-content-center">
                                        <div className='vending-button-content'>
                                            <h2 className="fw-bold mb-1 text-center button-sub-title ">O utilice  </h2>

                                            <button type="button" className="btn 
                                        btn-lg                                      
                                        mt-1 w-100
                                        btn-other                                         
                                        border-0"
                                                onClick={() => (setShowModalKeyCode(true))}>
                                                Ingresar el código de forma manual
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </main>
                    <input id="inputCodeQrOperator" ref={inputRef} type='text'  inputMode="none" onKeyPress={(e) => (handleChange(e))} className='vending-input-qr' autoFocus></input>


                </Modal.Body>
                
                <VendingModalKeyCode codeSesion={codeSesion} setCodeSesion={setCodeSesion} authMachineVending={authOperator} showModalKeyCode={showModalKeyCode} setShowModalKeyCode={setShowModalKeyCode}  ></VendingModalKeyCode>

                <Modal.Footer className='d-flex justify-content-start'>
                    <button className='btn btn-primary btn-vending-product m-2 rounded'

                        onClick={() => (setShowModalAuthOperator(false))}
                    >
                        <i className="uil uil-arrow-left"></i>
                        Regresar
                    </button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default VendingModalOperator